
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { CustomerClient } from '@/services/services';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class SavedLocations extends Vue {

    customer: OM.Customer = new OM.Customer();

    searchText: string = "";
    searchMode: boolean = false;
    loaded: boolean = false;

    created() {
        var loggedUser = StorageServices.getLoggedUser();
        CustomerClient.getProfile()
        .then(x => {
            this.customer = x;
            this.loaded = true;
        })
    }
}
